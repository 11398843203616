import { cssJoin } from '@volvo-cars/css/utils';
import { Icon } from '@volvo-cars/react-icons';
import { Track } from '@volvo-cars/tracking';
import React, { FC } from 'react';
import { BreadcrumbsViewProps } from './Breadcrumbs.types';

export const BreadcrumbsView: FC<BreadcrumbsViewProps> = ({
  containerAriaLabel,
  trail,
  currentTitle,
}) => (
  <>
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: trail.map(({ title, href }, index) => ({
            '@type': 'ListItem',
            position: index + 1,
            name: title,
            item: href,
          })),
        }),
      }}
    />
    <nav aria-label={containerAriaLabel}>
      <ol className="flex-row gap-8 py-16">
        {trail.map(({ title, href, eventLabel }, index) => {
          const isLastPage = index === trail.length - 1;
          return (
            <li
              key={title}
              className={cssJoin(!isLastPage && 'until-md:hidden')}
            >
              <Track eventLabel={eventLabel}>
                <a href={href} className="link-plain md:text-secondary">
                  {isLastPage && (
                    <span className="inline flex items-center h-1lh mr-8 md:hidden">
                      <Icon icon="chevron-back" size={12} />
                    </span>
                  )}
                  {title}
                </a>
              </Track>
              <span
                className="until-md:hidden ml-8 text-secondary"
                role="presentation"
              >
                /
              </span>
            </li>
          );
        })}
        <li className="until-md:hidden">
          <span role="link" aria-disabled="true" aria-current="page">
            {currentTitle}
          </span>
        </li>
      </ol>
    </nav>
  </>
);

export default BreadcrumbsView;
