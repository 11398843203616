import type { SiteSlug } from '@volvo-cars/market-sites';
import { getSiteSlug } from '@volvo-cars/market-sites/src/getSiteSlug';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect } from 'react';

const RETAIL_ASSISTED_SALES_PARAMS = [
  'dealer_associate',
  'sales_channel',
  'selling_dealer',
];

export const useRetailAssistedSales = () => {
  const router = useRouter();
  const pathname = usePathname();
  const market = getSiteSlug(pathname) as SiteSlug;

  useEffect(() => {
    if (['master', 'intl', 'us'].includes(market)) {
      return;
    }

    const retailAssistedSales: Record<string, unknown> = {};
    const queryParams = new URLSearchParams(window.location.search);

    for (const param of RETAIL_ASSISTED_SALES_PARAMS) {
      const value = queryParams.get(param);
      if (value) {
        retailAssistedSales[param] = value;
      }
    }

    if (Object.keys(retailAssistedSales).length === 0) return;

    if (
      typeof window !== 'undefined' &&
      window.sessionStorage &&
      typeof sessionStorage === 'object' &&
      Object.keys(retailAssistedSales).length
    ) {
      window.sessionStorage.setItem(
        'retailAssistedSales',
        JSON.stringify(retailAssistedSales),
      );
    }

    const stripped = new URLSearchParams();

    for (const [key, value] of queryParams.entries()) {
      if (!RETAIL_ASSISTED_SALES_PARAMS.includes(key)) {
        stripped.append(key, value);
      }
    }

    const strippedParams = stripped.toString();

    const href = `${pathname}${strippedParams ? `?${strippedParams}` : ''}`;
    router.replace(href, { scroll: false });
  }, [market, pathname, router]);
};
