import { getSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { SiteSlug } from '@volvo-cars/market-sites';

export const trailMap = (
  origin: string,
  siteSlug: SiteSlug,
  translate: ReturnType<typeof getSharedComponentsTranslate>,
) => ({
  home: {
    title: (translate('Breadcrumbs.breadcrumbs.home') || '') as string,
    href: `${origin}/${siteSlug}/`,
  },
  suv: {
    title: (translate('Breadcrumbs.breadcrumbs.suv') || '') as string,
    href: `${origin}/${siteSlug}/cars/suv/`,
  },
  sedan: {
    title: (translate('Breadcrumbs.breadcrumbs.sedan') || '') as string,
    href: `${origin}/${siteSlug}/cars/sedan/`,
  },
  wagon: {
    title: (translate('Breadcrumbs.breadcrumbs.wagon') || '') as string,
    href: `${origin}/${siteSlug}/cars/wagon/`,
  },
  'legacy-models': {
    title: (translate('Breadcrumbs.breadcrumbs.legacy-models') || '') as string,
    href: `${origin}/${siteSlug}/cars/legacy-models/`,
  },
  'car-finance': {
    title: (translate('Breadcrumbs.carFinance.car-finance') || '') as string,
    href: `${origin}/${siteSlug}/car-finance/`,
  },
  'car-finance-faq': {
    title: (translate('Breadcrumbs.carFinance.faq') || '') as string,
    href: `${origin}/${siteSlug}/car-finance/faq/`,
  },
  'care-by-volvo': {
    title: (translate('Breadcrumbs.carFinance.care-by-volvo') || '') as string,
    href: `${origin}/${siteSlug}/care-by-volvo/`,
  },
});
