import React, { FC } from 'react';
import { BreadcrumbsProps } from './Breadcrumbs.types';
import { trailMap } from './Breadcrumbs.utils';
import BreadcrumbsView from './Breadcrumbs.view';

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  trail,
  currentTitle,
  siteSlug,
  translate,
  origin,
}) => {
  const trails = trailMap(origin, siteSlug, translate);

  const containerAriaLabel =
    translate('Breadcrumbs.breadcrumbs.containerAriaLabel') || '';

  const generatedTrail = trail.map((item) =>
    typeof item === 'object'
      ? { ...item, eventLabel: `${item.title} | ${item.href}` }
      : {
          ...trails[item],
          eventLabel: `${trails[item].title} | ${trails[item].href}`,
        },
  );

  return (
    <BreadcrumbsView
      containerAriaLabel={containerAriaLabel}
      trail={generatedTrail}
      currentTitle={currentTitle}
    />
  );
};

export default Breadcrumbs;
