'use client';
import {
  Breadcrumbs as SharedBreadcrumbs,
  type BreadcrumbsProps as SharedBreadcrumbsProps,
} from '@vcc-package/text';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import type { DictionaryItemTypes } from '@vcc-www/shared-dictionaries/DictionaryItemTypes';
import { useVolvoCarsUrl } from '@vcc-www/volvo-cars-url';
import type { SiteSlug } from '@volvo-cars/market-sites';
import { getSiteSlug } from '@volvo-cars/market-sites/src/getSiteSlug';
import { TrackingProvider } from '@volvo-cars/tracking';
import { usePathname } from 'next/navigation';
import type React from 'react';

export const PageBreadcrumbs: React.FC<{
  currentTitle?: string;
}> = ({ currentTitle }) => {
  const pathname = usePathname();
  const url = useVolvoCarsUrl();
  const market = getSiteSlug(pathname) as SiteSlug;
  const translate = useSharedComponentsTranslate();

  const trail: SharedBreadcrumbsProps['trail'] = ['home'];

  const splitPath = pathname?.split('?')[0].split('/').filter(Boolean) ?? [];
  const lastPath = splitPath[splitPath.length - 1];
  const pathsToTrail = splitPath.slice(1, -1);

  // Check if the last path is 'faq' and rename it to 'car-finance-faq'
  const updatedPathsToTrail = pathsToTrail.map((path) =>
    path === 'faq' ? 'car-finance-faq' : path,
  ) as SharedBreadcrumbsProps['trail'];

  const mergedTrail = [...trail, ...updatedPathsToTrail];

  const translatedTitle = translate(
    `Breadcrumbs.carFinance.${lastPath}` as keyof DictionaryItemTypes,
  );

  return (
    <TrackingProvider eventCategory="page breadcrumbs">
      <div data-autoid="breadcrumbs" className="container">
        <SharedBreadcrumbs
          trail={mergedTrail}
          currentTitle={currentTitle ?? translatedTitle}
          siteSlug={market}
          translate={translate}
          origin={url.origin}
        />
      </div>
    </TrackingProvider>
  );
};
